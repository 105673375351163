<template>
  <div class="m-team-edit">
    <b-notification
      v-if="savingError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('team.savingError') }}
    </b-notification>
    <b-loading :active.sync="loading"></b-loading>
    <h1 class="title is-1">{{ heading }}</h1>
    <div class="columns">
      <div class="column is-half">
        <div class="container">
          <b-field :label="$t('team.name')" :type="nameType" :message="nameValidationMessage">
            <b-input
              v-model="name"
              :placeholder="$t('team.namePlaceholder')"
              :maxlength="limits.name"
              @input="resetValidation"
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="column">
        <b-field :label="$t('team.owner')" :type="ownerUserType" :message="ownerUserValidationMessage">
          <b-select v-model="ownerUser" :loading="loadingUsers" @change="resetValidation">
            <option v-for="user in users" :value="user._id" :key="user._id">
              {{ user.name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field>
          <b-field :label="$t('team.leader')">
            <b-select v-model="leaderUser" :loading="loadingUsers">
              <option v-for="user in users" :value="user._id" :key="user._id">
                {{ user.name }}
              </option>
            </b-select>
          </b-field>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-checkbox v-model="managed">
          {{ $t('team.managed') }}
        </b-checkbox>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field is-grouped">
          <div class="control">
            <b-button @click="cancel" type="is-secondary">{{ $t('generic.cancelButton') }}</b-button>
          </div>
          <div class="control">
            <b-button :disabled="loading" @click="dispatch" type="is-primary" :loading="saving">{{
              $t('generic.saveButton')
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as namespaces from '../../../store/namespaces';
import * as actions from '../../../store/actions';
import clientTeams from '../../../api/teams';
import logger from '../../../utils/logger';
import clientUsers from '../../../api/users';

export default {
  name: 'TeamEdit',
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      users: [],
      loadingUsers: false,
      limits: {
        name: 50
      },
      loading: false,
      loadingError: false,
      saving: false,
      savingError: false,
      name: '',
      leaderUser: '',
      ownerUser: '',
      managed: false,
      memberUsers: [],
      nameValidationMessage: '',
      nameType: '',
      ownerUserValidationMessage: '',
      ownerUserType: ''
    };
  },
  computed: {
    rolesRegistry() {
      if (this.$store.state.system.registries) {
        return this.$store.state.system.registries.roles;
      }
      return [];
    },
    isEdit() {
      return !!this.id;
    },
    heading() {
      if (this.isEdit) {
        return this.$t('team.editTeamHeading');
      } else {
        return this.$t('team.createTeamHeading');
      }
    }
  },
  methods: {
    createDataPayload() {
      const data = {
        name: this.name,
        leaderUser: this.leaderUser || null,
        ownerUser: this.ownerUser || null,
        managed: !!this.managed,
        memberUsers: this.memberUsers
      };
      if (this.isEdit) {
        return { id: this.id, data };
      } else {
        return data;
      }
    },
    loadTeam() {
      if (!this.id) {
        return false;
      }
      this.loading = true;
      this.loadingError = false;
      clientTeams
        .get(this.id)
        .then(team => {
          this.name = team.name;
          this.leaderUser = team.leaderUser ? team.leaderUser._id : '';
          this.ownerUser = team.ownerUser ? team.ownerUser._id : '';
          this.managed = team.managed;
          this.memberUsers = team.memberUsers;
        })
        .catch(error => {
          this.loadingError = true;
          logger.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // TODO: refactor, same method as in other modules (teams, feedback etc.)
    loadUsers() {
      this.loadingUsers = true;
      return clientUsers
        .list()
        .then(users => {
          let usersForSelect = [];
          usersForSelect.push({
            _id: '',
            name: this.$t('generic.none')
          });

          users.forEach(user => {
            let company = user.company ? ' (' + user.company.name + ')' : '';
            usersForSelect.push({
              _id: user._id,
              name: user.nameLast + ' ' + user.nameFirst + company
            });
          });
          this.users = usersForSelect;
        })
        .finally(() => {
          this.loadingUsers = false;
        });
    },
    isFormValid() {
      let status = true;
      if (this.name === '') {
        (this.nameType = 'is-danger'), (this.nameValidationMessage = this.$t('team.emptyNameError'));
        status = false;
      }
      if (this.ownerUser === '') {
        (this.ownerUserType = 'is-danger'), (this.ownerUserValidationMessage = this.$t('team.emptyOwnerError'));
        status = false;
      }
      return status;
    },
    resetValidation() {
      this.nameType = '';
      this.nameValidationMessage = '';
      this.ownerUserType = '';
      this.ownerUserValidationMessage = '';
    },
    dispatch() {
      if (!this.isFormValid()) {
        return false;
      }
      this.saving = true;
      this.savingError = false;
      let payload = this.createDataPayload();
      this.$store
        .dispatch(namespaces.TEAMS + (this.isEdit ? actions.EDIT_TEAM : actions.ADD_TEAM), payload)
        .then(() => {
          this.$router.replace({ name: 'teams-list' });
        })
        .catch(error => {
          this.savingError = true;
          logger.error(error);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    cancel() {
      this.name = '';
      this.managed = false;
      this.ownerUser = '';
      this.leaderUser = '';
      this.$router.go(-1);
    }
  },
  mounted() {
    this.loadTeam();
    this.loadUsers();
  },
  watch: {
    id() {
      this.loadTeam();
    }
  }
};
</script>
